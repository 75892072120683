<template>
  <div class="animated fadeIn">
    <b-container>
      <b-card>
        <loading
          :active.sync="isLoading"
          :is-full-page="false"
          :color="this.$config.LOADER.color"
          :opacity="this.$config.LOADER.opacity"
          :background-color="this.$config.LOADER.backgroundColor"
          :z-index="10000"
        ></loading>
        <h5 slot="header" class="mb-0">
          <b-button
            size="sm"
            :to="{
              name: 'admin.customer-loan-accounts.detail',
              params: { id: this.$route.params.id },
            }"
            class="mr-2"
            ><i class="fa fa-chevron-left"></i> Буцах</b-button
          >
        </h5>
        <b-form @submit.stop.prevent="saveData">
          <h4 class="mb-0">Барьцаа</h4>
          <hr class="mt-2" />

          <b-row>
            
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Үйлдвэрлэсэн он"
                label-for="input-factory_year"
              >
                <money
                  id="input-factory_year"
                  class="form-control"
                  v-model="form.factoryYear"
                  type="text"
                  required
                  placeholder="Үйлдвэрлэсэн он"
                  suffix=""
                  thousands=""
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="МУ-д орж ирсэн он"
                label-for="input-income_year"
              >
                <b-form-datepicker
                  id="input-income_year"
                  v-model="form.incomeYear"
                  placeholder="Сонгоно уу"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  autocomplete="off"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Марк"
                label-for="input-mark"
              >
                <b-form-input
                  id="input-mark"
                  v-model="form.mark"
                  placeholder="Марк"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Улсын дугаар"
                label-for="input-country_code"
              >
                <b-form-input
                  id="input-country_code"
                  v-model="form.countryCode"
                  placeholder="Улсын дугаар"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Арлын дугаар"
                label-for="input-registeration_code"
              >
                <b-form-input
                  id="input-registeration_code"
                  v-model="form.registrationCode"
                  placeholder="Арлын дугаар"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Өнгө"
                label-for="input-color"
              >
                <b-form-input
                  id="input-color"
                  v-model="form.color"
                  placeholder="Өнгө"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="GPS serial"
                label-for="input-gps_serial"
              >
                <b-form-input
                  id="input-gps_serial"
                  v-model="form.gpsSerial"
                  placeholder="GPS serial"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Дата сим дугаар"
                label-for="input-data_sim_number"
              >
                <b-form-input
                  id="input-data_sim_number"
                  v-model="form.dataSimNumber"
                  placeholder="Дата сим дугаар"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col></b-col>
          </b-row>

          <h4 class="mb-0">Даатгал</h4>
          <hr class="mt-2" />

          <b-row>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Даатгалын нэр"
                label-for="input-insurance_name"
              >
                <b-form-input
                  id="input-insurance_name"
                  v-model="form.insuranceName"
                  placeholder="Даатгалын нэр"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Даатгалын төрөл"
                label-for="input-insurance_type"
              >
                <b-form-input
                  id="input-insurance_type"
                  v-model="form.insuranceType"
                  placeholder="Даатгалын төрөл"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col> </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Эхлэх огноо"
                label-for="input-start_date"
              >
                <b-form-datepicker
                  id="input-start_date"
                  v-model="form.insuranceStartDate"
                  placeholder="Сонгоно уу"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  locale="mn"
                  autocomplete="off"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Дуусах огноо"
                label-for="input-end_date"
              >
                <b-form-datepicker
                  id="input-end_date"
                  v-model="form.insuranceEndDate"
                  placeholder="Сонгоно уу"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  autocomplete="off"
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col> </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Үнэлгээ хийсэн дүн"
                label-for="input-evaluation_amount"
              >
                <money
                  id="input-evaluation_amount"
                  class="form-control"
                  v-model="form.insuranceEvaluationAmount"
                  type="text"
                  required
                  placeholder="Үнэлгээ хийсэн дүн"
                  suffix=" ₮"
                  thousands=","
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Даатгалын хувь "
                label-for="input-percent"
              >
                <money
                  id="input-percent"
                  class="form-control"
                  v-model="form.insurancePercent"
                  type="text"
                  required
                  placeholder="Даатгалын хувь "
                  suffix=" %"
                  thousands=","
                  :precision="1"
                ></money>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="input-group-payDescription"
                label="Даатгал хийлгэсэн дүн"
                label-for="input-amount"
              >
                <money
                  id="input-amount"
                  class="form-control"
                  v-model="form.insuranceAmount"
                  type="text"
                  required
                  placeholder="Даатгал хийлгэсэн дүн"
                  suffix=" ₮"
                  thousands=","
                  :precision="0"
                ></money>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button type="submit" variant="success">Хадгалах</b-button>
        </b-form>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "new-loan",
  data: function() {
    return {
      isLoading: false,
      form: {
        id: 0,
        factoryYear: 0,
        incomeYear: null,
        mark: "",
        countryCode: "",
        registrationCode: "",
        color: "",
        gpsSerial: "",
        dataSimNumber: "",
        insuranceName: "",
        insuranceType: "",
        insuranceStartDate: null,
        insuranceEndDate: null,
        insuranceEvaluationAmount: 0,
        insurancePercent: 0,
        insuranceAmount: 0,
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "LoanWebService/get_deposit_insurance_data",
          {
            params: { id: this.$route.params.id },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.$data.form = response.body;
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    saveData: function() {
      this.isLoading = true;
      //console.log(this.$data.form)
      this.$http
        .post(
          this.$config.API_URL + "LoanWebService/create_deposit_insurance",
          {
            loanAccountId: this.$route.params.id,
            id: this.$data.form.id,
            data: JSON.stringify(this.$data.form),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            this.isLoading = false;
            //console.log(response.body)
            if (response.body.success) {
              //console.log('asdf')
              this.$router.push({
                name: "admin.customer-loan-accounts.detail",
                params: { id: this.$route.params.id },
              });
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
  },
};
</script>
